// src/App.js

import React from 'react';
import TerminalComponent from './TerminalComponent';

const App = () => (
  <div>
    <meta name="google-site-verification" content="jsOFBriaz8RJhX-4fkf-roEXs_NYiIJjdGwDl4Kfvao" />
    <TerminalComponent />
  </div>
);

export default App;

